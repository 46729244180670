/*
 Constante para definir el entorno en el que se encuentra la app
 Posibles valores:
 - production
 - local
 */

export const ENV = 'qa';

// Constante para definir la version de la API
export const API_VERSION = 'v3';

// ENDPOINTS de  aplicacion en los diferentes entornos
// Nota: las claves de este objeto deben coincidir con el (o los) valores de la constante ENV
export const ENDPOINTS = {
    API: {
        dev: '',
        qa: 'https://api-qa-susa.nodobanka.com',
        stage: '',
        production: '',
        local: '', //susa dev
    },
};

// Duración de la sesión en minutos
export const  TIME = 30;

export const KEY = {
    dev: '6LdgU7gUAAAAAG51Ai2oqpeBlNHmlQVDNz4rKDwL',
    qa: '6LdgU7gUAAAAAG51Ai2oqpeBlNHmlQVDNz4rKDwL',
    local: '6LewoKwUAAAAAFugBhBHHcBFbc4gRTunW-X2uSsG',
};
