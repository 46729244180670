const en = {
  translations: {
    search: 'Buscar',
    acceptTerms: 'Debe aceptar los términos y condiciones',
    addComment: 'Agregar comentario ...',
    signUpTitle: 'El mundo tecnológico',
    signUpSubtitle: 'Un mundo conectado por internet, requiere personas preparadas para hacerlo.',
    userDataIncorrect: 'Datos de usuario incorrecto',
    logout: 'Cerrar Sesión',
    profile: 'Perfil',
    welcome: 'Bienvenido',
    user: 'usuario',
    accountDetails: 'Lista de operaciones',
    welcomeTo: 'Bienvenido al',
    operatingBalance: 'Saldo Operativo',
    balancePendingReconcile: 'Saldo pendiente por conciliar',
    balanceChargePassengers: 'Carga de saldo pasajeros',
    documentType: 'Tipo de documento',
    document: 'Documento',
    connectionError: 'Error de conexión',
    fullName: 'Nombre Completo',
    email: 'Correo Electrónico',
    rechargeAmount: 'Monto Acreditado',
    return: 'Volver',
    transactionCode: 'Código de transacción',
    date: 'Fecha',
    transactionCharge: 'Cargo por la transacción',
    requiredUser: 'Usuario requerido',
    depositRegister: 'Cargar Depósito',
    detailRegister: 'Confirmación de datos',
    continue: 'Siguiente',
    confirm: 'Confirmar',
    funcAdmin: 'Funciones Administrativas',
    accountAdmin: 'Cuenta',
    lotClosure: 'Cierre de lote',
    loadBalance: 'Recarga de Saldo',
    previous: 'Anterior',
    next: 'Siguiente',
    selectTypeTransactions: 'Tipo de transacción',
    all: 'Todos',
    hour: 'Hora',
    deposit: 'Depósito',
    transferOut: 'Recibo de dinero',
    typeOfTransaction: 'Tipo de Transacción',
    amount: 'Monto',
    status: 'Estado',
    sessionExpired: 'Han transcurrido {{TIMESESSION}} minutos de inactividad,\nse cerró la sesión por seguridad.',
    accept: 'Aceptar',
    addNewVoucher: 'Agregar nuevo comprobante',
    banks: 'Bancos',
    selectBank: 'Seleccioná Banco',
    numberTransaction: 'N° Transacción',
    addNumberT: 'Ingrese número',
    depositedAmount: 'Monto depositado',
    addAmount: 'Ingrese monto',
    attachVoucher: 'Adjuntar comprobante',
    optional: 'Opcional',
    successOperation: 'Operación exitosa',
    errorOperation: 'Error al procear la solicitud. Intentá más tarde.',
    finish: 'Finalizar',
    currentBalance: 'Saldo actual del pasajero',
    showTransacction: 'Ver detalle',
    dateStart: 'Fecha inicio',
    dateEnd: 'Fecha fin',
    actions: 'Acciones',
    withoutAttached: 'Sin adjuntar',
    yes: 'Si',
    loadDepositText: 'Se ha registrado correctamente el depósito',
    detailtransaction: 'Detalle de transacción',
    bank: 'Banco',
    documentPassengers: 'Documento del Pasajero',
    acceptDepositConfirm: '¿Estás seguro que querés cargar el depósito?',
    continueButton: 'Continuar',
    requiredCaptcha: 'El captcha es requerido...',
    userInformation: 'Datos de la Agencia',
    changePassword: 'Cambio de contraseña',
    profileUser: 'Perfil de la Agencia',
    password: 'Contraseña',
    newPassword: 'Nueva contraseña',
    repeatPassword: 'Repita nueva contraseña',
    emptyFields: 'Debes completar todos los campos.',
    samePassword: 'La contraseña nueva debe coincidir',
    save: 'Guardar',
    userType: 'Tipo de usuario',
    legalRepresentative: 'Representante Legal',
    numberDocument: 'Documento de identificación',
    personalData: 'Datos Personales',
    accountData: 'Datos de la Cuenta',
    agencyData: 'Datos de la Agencia',
    addressAgency: 'Dirección',
    cityAgency: 'Ciudad',
    province: 'Provincia',
    cuit: 'CUIT',
    nameAgency: 'Nombre de la Agencia',
    nameLastName: 'Nombre y Apellido',
    name: 'Nombre',
    titlecash: 'Caja',
    amountcash: 'Saldo en caja',
    detailcash: 'Detalle de caja',
    openingDate: 'Fecha de apertura',
    closeBoxLogout: 'Cerrar Caja y Cerrar Sesión',
    acceptCashClosure: '¿Estás seguro de realizar un cierre de caja?',
    moreInformation: 'Más información',
    viewDetails: 'Ver detalles',
    selectPassenger: 'Seleccione el pasajero',
    notDocRegister: 'Número de documento no se encuentra registrado',
    lastName: 'Apellido',
    username: 'Usuario',
    closeBatch: 'Cerrar lote',
    lastClosing: 'Último cierre',
    closedLots: 'Lotes Cerrados',
    commission: 'Comisión',
    close: 'Cerrar',
    lotResult: 'Resultado de lote',
    lotNumber: 'Número de lote',
    lotSuccess: 'Cierre de lote exitoso',
    lotError: 'Fallo en cierre de lote. Intente más tarde',
    lotTransactions: 'Transacciones del lote',
    downloadPdf: 'Descargar PDF',
    balance: 'Saldo',
    numberOperations: 'Número de operaciones',
    stateLots: 'Estados de Lotes/Comisiones',
    selectStateLot: 'Seleccione el estado del lote',
    dateTime: 'Fecha y Hora',
    numLot: 'Número de lote',
    amountLot: 'Monto del lote',
    seeDetail: 'Ver Detalle',
    noData: 'Sin registros',
    detailLot: 'Detalle del Lote',
    showVoucher: 'Ver Comprobante',
    totalElements: 'Total de Elementos ',
    totalLots: 'Total de Lotes ',
    goBack: 'Regresar',
    numBox: 'Nro. Caja',
    dateOpen: 'Fecha de Apertura',
    dateClose: 'Fecha de Cierre',
    numOperations: 'Cant. Operaciones',
    voucherNotAvailable: 'Comprobante no disponible',
    operatorAgency: 'Operadores de la Agencia',
    role: 'Rol',
    userState: 'Estado',
    nameFull: 'Nombre y Apellido',
    createdDate: 'Fecha de Alta',
    operatorsTotal: 'Total de operadores ',
    activeDisableOperatorSuccess: 'Se {{status}} el operador correctamente',
    activeDisableOperatorError: 'No se pudo {{status}} el operador',
    active: 'Activo',
    inactive: 'Inactivo',
    finishCashClosure: 'Cierre de caja exitoso',
    errorCashClosure: 'Error en Cierre de Caja',
    closeCash: '¿Desea cerrar también la caja?',
    amountCommission: 'Monto Comisión',
    amountWithoutCommission: 'Monto Sin Comisión',
    returnarrow: '< Regresar',
    noRecipient: 'Sin Destinatario',
    noDetail: 'Sin detalle',
    noPdf: 'Sin Documento',
    noLot: 'Sin número de lote',
    activeDisabled: 'Activo /Inactivo',
    balanceBox: 'Saldo de caja',
    voucher: 'Comprobante',
    noCode: 'Sin código',
    urbanServices: 'Servicios Urbanos',
    transactionsOutLot: 'Transacciones sin lote',
    totalLot: 'Total por lotear',
    numberTransactions: 'Número de Transacciones',
    exportExcel: 'Exportar Excel',
    phoneNumber: 'N° de Teléfono',
    contact: 'Contacto',
    contactBook: 'Agenda de Destinatarios',
    accountDestiny: 'Cuenta Destino',
    alias: 'Alias',
    availableBalance: 'Saldo Disponible',
    deleteContact: 'Contacto eliminado correctamente',
    sendMoney: 'Envio de Dinero',
    sendCvuCbuOrAlias: 'Enviar dinero a CBU/CVU o Alias',
    originAccount: 'Cuenta origen',
    limitToTransfer: 'Las transferencias entre cuentas internas y externas se realizan entre las 5:00 hrs  y las 22:00 hrs',
    findRecipient: 'Buscar destinatario',
    makeNewTransfer: 'Realizar nueva transferencia',
    enterCbuCvuUser: 'Ingresa CBU/CVU o alias válido',
    recipient: 'Destinatario',
    concept: 'Concepto',
    insufficientFunds: 'No posee fondos suficientes',
    toSelect: 'Seleccionar',
    toDelete: 'Eliminar',
    aliasContact: 'Alias de contacto',
    noAddRecipient: 'No agregar contacto',
    successfullOperation: 'Operación realizada exitosamente',
    totalTransferred: 'Total Transferido',
    calendar: 'Calendario',
    cvu: 'CVU',
    detailOperator: 'Detalle del operador',
    chargeQr: 'Cobrar con QR',
    paymentQr: 'Cobro QR',
    amountCollect: 'Monto a cobrar',
    collectionConcept: 'Concepto del cobro',
    userRequestQr: 'Solicita al usuario que escanee el Código QR',
    cancelPayment: 'Cancelar cobro',
    successPaymentQr: 'Cobro QR exitoso',
    paymentReceived: 'Recibiste un pago de',
    codeQr: 'Código QR',
    scanPayment: 'Escaneá y pagá',
    agency: 'Agencia',
    retreats: 'Retiros',
    codeSecurity: 'Código de seguridad',
    documentAndCode: 'Ingresar documento y código de seguridad',
    confirmDataRetreats: 'Confirme los datos del retiro',
    cancel: 'Cancelar',
    downloadQr: 'Descargar QR',
    extractionSuccess: 'Esta solicitud ya ha sido paga',
    passenger: 'Pasajero',
    messageExtraction: 'No hay fondos disponibles en caja',
    processingRequest: 'Procesando solicitud, por favor espere',
    balanceCash: 'Saldo efectivo',
    paymentSuccess: 'Solicitud procesada exitosamente',
    notAlias: 'Sin alias',
    comingSoon: 'Esta funcionalidad estará disponible próximamente',
    chargeLawDescription: 'Impuesto Ley 25.413 Crédito/Débito',
    beneficiary: 'Beneficiario',
    cbuOrAlias: 'CBU/CVU o alias',
    totalToTransferred: 'Total a Transferir',
    chargeTaxes: 'Cargo + IVA',
    addRecipient: 'Agregar destinatario al directorio',
    confirmInformation: 'Confirmar datos',
    amountToReceive: 'Cantidad a recibir',
    insufficientBalance: 'Tu cuenta no posee suficientes fondos para este envío. Ingresa otro monto. Tu balance actual es de {{balance}}',

  },
};

export default en;
