import React                        from 'react';
import { Form, FormElement, Panel } from '../../../../components';
import './styles.scss';
import { withTranslation }          from "react-i18next";

const FormDepositRegister = ({
                               t, onSubmit, onChangeInput, numberTransc, typeBank, typesBanks, amount, disabled, selectedFile, imagePreviewUrl, handleChangeUploadFile
                             }) => {

  return (
    <Panel headingText={t('depositRegister')}>
      <Form submitText={t('continue')} onSubmit={(e) => onSubmit(e)} disabled={disabled}>

        <div className="columns is-centered content-form">
          <div className="content-inputs">
            <FormElement
              name='typeBank'
              onChange={ev => onChangeInput(ev)}
              value={typeBank}
              label={t('selectBank')}
              optionsSelect={typesBanks}
              placeholder={t('banks')}
              typeElement="select"
              controlClasses="select"
            />
          </div>
          <div className="content-inputs">
            <FormElement
              onChange={onChangeInput}
              label={t('numberTransaction')}
              placeholder={t('addNumberT')}
              controlClasses="input-custom"
              name='numberTransc'
              value={numberTransc}
            />
          </div>
          <div className="content-inputs">
            <FormElement
              onChange={onChangeInput}
              label={t('depositedAmount')}
              placeholder={t('addNumberT')}
              controlClasses="input-custom"
              name='amount'
              value={amount}
              typeElement={'amountFormat'}
            />
          </div>
          <div className="content-inputs has-text-centered">
            <div className="fields">
              <div className='add-voucher'>
                <input type="file"
                       onChange={handleChangeUploadFile}
                       className="input-file input-upload"
                       id="file-upload2"
                       name="file_upload"
                       accept={'image/*'}
                />
                <div className='upload-label'>
                  <span>{t('attachVoucher')}</span>
                </div>
                <div className='upload-voucher'>
                  {imagePreviewUrl ? <img src={imagePreviewUrl} /> : <i className="far fa-file-image"></i>}
                </div>
                <span className="name-file">{selectedFile ? selectedFile.name : ''}</span>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Panel>
  );
}

export default withTranslation()(FormDepositRegister);
